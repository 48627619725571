.error-page {
  width: 100%;
  padding: 200px 0px 150px;

  @media (max-width: 1200px) {
    padding: 200px 0px 100px;
  }

  @media (max-width: 900px) {
  }

  @media (max-width: 600px) {
    padding: 100px 0px 100px;
  }

  .wrapper {
    width: 65%;
    margin: 0 auto;

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }

    .error-content {
      padding: 100px;
      background: linear-gradient(
        180deg,
        rgba(249, 249, 249, 0.29) -14.99%,
        rgba(85, 202, 255, 0.52) 118.32%
      );
      // text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-radius: 4px;

      @media (max-width: 600px) {
        padding: 60px 10px;
      }

      h2 {
        font-size: 180px;
        line-height: 180px;
        color: var(--btn-color);

        @media (max-width: 600px) {
          font-size: 80px;
          line-height: 80px;
        }
      }

      h5 {
        font-size: 20px;
        line-height: 22px;
        color: var(--btn-color);
      }
      p {
        color: var(--pry-color);
        width: 500px;
        text-align: center;
        margin: 20px 0;

        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
}
