.project-hero {
    width: 100%;
    padding: 50px 0;
    position: relative;
  
  
    .hero-skew {
      background: linear-gradient(
        180deg,
        rgba(249, 249, 249, 0.29) -14.99%,
        rgba(85, 202, 255, 0.52) 118.32%
      );
      width: 100% !important;
      height: 100% !important;
      position: absolute;
      top: 0;
      padding: 40px;
  
      @media (max-width: 1200px) {
      }
  
      @media (max-width: 900px) {
      }
  
      @media (max-width: 600px) {
      }
    }
  
    .wrapper {
      width: 97.15%;
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
  
      @media (max-width: 1387px) {
        width: 90%;
        justify-content: center;
      }
  
      @media (max-width: 1315px) {
        width: 95%;
        justify-content: center;
      }
  
      @media screen and (max-width: 1200px) {
      }
      @media screen and (max-width: 900px) {
        display: inline-block;
      }
  
      .left {
        width: 35%;
        padding-top: 180px;
        color: var(--head-txt);
        position: relative;
  
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
  
        @media screen and (max-width: 900px) {
        }
  
        @media screen and (max-width: 600px) {
          padding-top: 60px;
        }
  
        span {
          a {
            font-family: Poppins-Bold, sans-serif;
            text-decoration: underline;
            color: var(--btn-color) !important;
            font-size: 20px;
            display: inline-block !important;
            padding-left: 5px;
          }
        }
  
        h2 {
          animation-name: fadeInDown;
          animation-duration: 2s;
        }
        p {
          animation-name: fadeInRight;
          animation-duration: 2s;
        }
        a {
          animation-name: fadeInUp;
          animation-duration: 2s;
        }
  
        h2 {
          font-size: 72px;
          font-style: normal;
          line-height: 75px;
          max-width: 570px !important;
          letter-spacing: 1px;
          font-weight: 700;
  
          @media (max-width: 1387px) {
            font-size: 70px;
            font-style: normal;
            line-height: 88px;
            max-width: 600px;
            letter-spacing: 1px;
            font-weight: 700;
          }
  
          @media screen and (max-width: 1200px) {
            max-width: 750px;
            font-size: 68px;
            font-style: normal;
            line-height: 80px;
            font-weight: 700;
          }
  
          @media screen and (max-width: 900px) {
            max-width: 750px;
            font-size: 60px;
            font-style: normal;
            line-height: 65px;
            font-weight: 700;
          }
  
          @media screen and (max-width: 700px) {
            max-width: 300px;
            font-size: 40px;
            font-style: normal;
            line-height: 50px;
            font-weight: 700;
            padding: 0px 20px;

          }
        }
  
        p {
          max-width: 700px !important;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: var(--text-sec);
          letter-spacing: 1px;
          margin: 20px 0 70px 0;
          // opacity: 0.7;

          @media screen and (max-width:900px) {
            padding: 0px 15px
          }
        }
        .btn-mobile{
          @media screen and(max-width: 900px) {
            width:290px;
            margin: 0 auto;
          }
        }
      }
  
      .right {
        // flex: 1;
        height: 100%;
        width: 50%;
  
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
  
        img {
          // width: 300px;
          z-index: 10;
          position: relative;
          width: 100%;
          margin-top: 20px;
          animation-name: fadeInDown;
          animation-duration: 2s;
  
          @media (max-width: 1387px) {
            // width: 96%;
          }
  
          @media (max-width: 1315px) {
            // width: 100%;
          }
  
          @media (max-width: 1264px) {
            display: inline-block;
          }
  
          @media screen and (max-width: 900px) {
            max-height: 400px;
          }
        }
      }
    }
  }
  