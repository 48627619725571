.contact-hero {
  width: 100%;
  padding: 50px 0 260px 0;
  position: relative;

  .round-blur {
    position: absolute;
    right: 20px;
    bottom: 10px;
    z-index: 2;
    width: 280px;
    height: 280px;

    @media (max-width: 1387px) {
      right: 20px;
      bottom: 250px;
    }
    @media (max-width: 1200px) {
      width: 100px;
      height: 100px;
      left: 200px;
      top: 20px;
      display: block;
    }

    @media (max-width: 900px) {
      width: 100px;
      height: 100px;
      left: 200px;
      top: 20px;
      display: block;
    }

    @media (max-width: 600px) {
      width: 100px;
      height: 100px;
      left: 200px;
      top: 20px;
      display: block;
    }
  }

  .round-blur-med {
    position: absolute;
    left: 20px;
    top: 200px;
    z-index: 2;
    width: 200px;
    height: 200px;

    @media (max-width: 1387px) {
      display: none;
    }

    @media (max-width: 1200px) {
      display: none;
    }

    @media (max-width: 900px) {
      display: none;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  .round-blue-large {
    position: absolute;
    right: -30px;
    top: -95px;
    z-index: 2;
    display: block;

    @media (max-width: 1387px) {
      display: none;
    }

    @media (max-width: 1200px) {
      width: 100px;
      height: 100px;
      right: 190px;
      top: -30px;
      display: block;
    }
    @media (max-width: 900px) {
      width: 100px;
      height: 100px;
      right: 190px;
      top: -30px;
      display: block;
    }
    @media (max-width: 600px) {
      width: 100px;
      height: 100px;
      right: 190px;
      top: -30px;
      display: block;
    }
  }

  .round-blue-small {
    position: absolute;
    left: 20px;
    top: 10px;
    z-index: 2;

    @media (max-width: 1200px) {
      display: none;
    }

    @media (max-width: 900px) {
      display: none;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  .blue-cookie {
    position: absolute;
    right: 200px;
    top: 140px;
    z-index: 2;

    @media (max-width: 1200px) {
      display: none;
    }

    @media (max-width: 900px) {
      display: none;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  .hero-skew {
    background: linear-gradient(
      180deg,
      rgba(249, 249, 249, 0.29) -14.99%,
      rgba(85, 202, 255, 0.52) 118.32%
    );
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    padding: 40px;

    @media (max-width: 1200px) {
    }

    @media (max-width: 900px) {
    }

    @media (max-width: 600px) {
    }
  }

  .wrapper {
    width: 65%;
    margin: 0 auto;
    display: flex;

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }

    @media screen and (max-width: 1200px) {
    }

    .left {
      width: 60%;
      padding-top: 100px;
      color: var(--head-txt);
      position: relative;

      @media screen and (max-width: 1200px) {
        width: 100%;
      }

      @media screen and (max-width: 900px) {
      }

      @media screen and (max-width: 600px) {
        padding-top: 60px;
      }

      span {
        a {
          font-family: Poppins-Bold, sans-serif;
          text-decoration: underline;
          color: var(--btn-color) !important;
          font-size: 20px;
          display: inline-block !important;
          padding-left: 5px;
        }
      }

      h2 {
        animation-name: fadeInDown;
        animation-duration: 2s;
      }
      p {
        animation-name: fadeInRight;
        animation-duration: 2s;
      }
      a {
        animation-name: fadeInUp;
        animation-duration: 2s;
      }

      h2 {
        font-size: 72px;
        font-style: normal;
        line-height: 75px;
        max-width: 570px !important;
        letter-spacing: 1px;
        font-weight: 700;

        @media (max-width: 1387px) {
          font-size: 70px;
          font-style: normal;
          line-height: 88px;
          max-width: 600px;
          letter-spacing: 1px;
          font-weight: 700;
        }

        @media screen and (max-width: 1200px) {
          max-width: 750px;
          font-size: 68px;
          font-style: normal;
          line-height: 80px;
          font-weight: 700;
        }

        @media screen and (max-width: 900px) {
          max-width: 750px;
          font-size: 60px;
          font-style: normal;
          line-height: 65px;
          font-weight: 700;
        }

        @media screen and (max-width: 700px) {
          max-width: 300px;
          font-size: 40px;
          font-style: normal;
          line-height: 50px;
          font-weight: 700;
        }
      }

      p {
        max-width: 700px !important;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: var(--text-sec);
        letter-spacing: 1px;
        margin: 20px 0 70px 0;
        // opacity: 0.7;
      }
    }

    .right {
      flex: 1;
      height: 100%;

      @media screen and (max-width: 1200px) {
        width: 100%;
      }

      img {
        width: 76%;
        height: 100%;

        @media (max-width: 1387px) {
          width: 96%;
        }

        @media (max-width: 1315px) {
          width: 100%;
        }

        @media (max-width: 1264px) {
        }

        @media screen and (max-width: 900px) {
          max-height: 400px;
        }
      }
    }
    // }
  }

  .form-container {
    position: absolute;
    margin-left: -32.5%;
    left: 50%;
    background-color: var(--white);

    @media (max-width: 1387px) {
      // width: 90%;
      margin-left: -45%;
    }

    @media (max-width: 1315px) {
      margin-left: -47.5%;
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }

    form {
      width: 100%;
      padding: 60px;
      box-shadow: 0px 15px 26px rgba(1, 73, 159, 0.05);
      border-radius: 7px;

      @media (max-width: 600px) {
        padding: 20px;
      }

      h3 {
        font-size: 30px;
        color: var(--head-txt);

        &:first-of-type {
          margin-bottom: 20px;
        }

        &:last-of-type {
          margin-top: 20px;
        }
      }

      .form-grid {
        width: var(--full);
        display: grid;
        grid-template-columns: repeat(3, 1fr);
       column-gap: 20px;
       row-gap: 8px;
        align-items: center;
        justify-content: center;

        &.mb20 {
          margin-bottom: 20px;
        }

        &.mb48 {
          margin-bottom: 48px;
        }

        @media (max-width: 600px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      .attachment-grid {
        width: var(--full);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
       column-gap: 20px;
       row-gap: 8px;
        align-items: center;
        justify-content: center;

        &.mb20 {
          margin-bottom: 20px;
        }

        &.mb48 {
          margin-bottom: 48px;
        }

        @media (max-width: 600px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      .textarea {
        margin-top: 20px;
        height: 206.5px;

        .error-msg {
          margin-top: 0px;
        }
      }

      .service-needed {
        margin-top: 20px;

        .check-title {
          margin-bottom: 20px;
          display: flex;
          align-items: center;

          span {
            margin-right: 10px;
          }

          p {
            font-family: Poppins-Medium, sans-serif;
            font-style: normal;
            font-weight: 500;

            color: #093867;
          }
        }
        .checkbox-group-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          @media (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start;
          }

          .checkbox-group {
            margin-right: 60px;
            width: fit-content;

            &:last-of-type {
              margin-right: 0;
            }

            @media (max-width: 600px) {
              margin-right: 0px;
              margin-bottom: 20px;
              width: 100%;

              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .formGroup {
        height: 108px;
      }

      .formGroupFile {
        .file-head {
          display: flex;
          justify-content: space-between;

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #74808c;
          }

          .file-title {
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            span {
              margin-right: 10px;
            }

            p {
              font-family: Poppins-Medium, sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              color: #093867;
            }
          }
        }
      }

      .btn-container {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }

      // material ui slider
      .MuiSlider-rail {
        width: 100%;
        height: 5px;
        display: block;
        opacity: 0.38;
        position: absolute;
        border-radius: 1px;
        background-color: #d5d3d3;
        border-radius: 4px;
      }

      .MuiSlider-root {
        color: var(--head-txt);
        width: 100%;
        cursor: pointer;
        height: 5px;
        display: inline-block;
        padding: 13px 0;
        position: relative;
        box-sizing: content-box;
        touch-action: none;
        -webkit-tap-highlight-color: transparent;
      }

      .MuiSlider-thumb {
        width: 14px;
        height: 14px;
        display: flex;
        outline: 0;
        position: absolute;
        box-sizing: border-box;
        margin-top: -5px;
        transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        align-items: center;
        margin-left: -6px;
        border-radius: 50%;
        justify-content: center;
        background-color: var(--white);
        border: solid 2px var(--head-txt);
      }

      .MuiSlider-track {
        height: 5px;
        display: block;
        position: absolute;
        border-radius: 1px;
        background-color: var(--head-txt);
      }

      .address-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 40px;

        .icon-text {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }

          span {
            margin-right: 10px;
          }

          p {
            color: var(--head-txt);
            font-size: 18px;

            @media (max-width: 600px) {
              font-size: 14px;
            }
          }
        }
      }

      .slider-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 40px 0px;

        @media (max-width: 600px) {
          flex-direction: column;
        }

        .slider-item {
          width: 100%;
          margin-right: 150px;

          &:last-of-type {
            margin-right: 0px;
          }

          @media (max-width: 600px) {
            margin: 20px 0px;
          }
          .slider-head {
            display: flex;
            justify-content: space-between;

            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #74808c;
            }
            .check-title {
              margin-bottom: 10px;
              display: flex;
              align-items: center;

              span {
                margin-right: 10px;
              }

              p {
                font-family: Poppins-Medium, sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                color: #093867;
              }
            }

            &:last-of-type {
              margin-right: 0px;
            }
          }
        }
      }

      .swal2-styled.swal2-confirm {
        border: 0;
        border-radius: 0.25em;
        background: initial;
        background-color: var(--head-txt);
        color: #fff;
        font-size: 1em;
      }
    }
  }
}
