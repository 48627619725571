.our-toolbox {
  width: 100%;
  padding: 100px 0 50px;
  position: relative;

  .blur-image {
    img {
      position: absolute;
      top: 60px;
      left: -30px;

      @media (max-width: 900px) {
        display: none;
      }
      @media (max-width: 605px) {
        display: none;
      }
    }
  }

  .wrapper {
    width: 65%;
    margin: 0 auto;

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;
      max-width: 700px;
      margin: 0 auto;

      span {
        font-weight: 700;
      }

      img {
        width: 10.5%;

        @media screen and (max-width: 700px) {
          width: 20%;
        }
      }

      h2 {
        color: var(--head-txt);
        font-weight: 700;
        font-size: 72px;
        line-height: 105%;
        margin: 15px 0;

        @media (max-width: 1387px) {
          font-size: 65px;
          font-style: normal;
          line-height: 67px;
          font-weight: 700;
        }

        @media screen and (max-width: 900px) {
          font-size: 60px;
          font-style: normal;
          line-height: 62px;
          font-weight: 700;
        }

        @media screen and (max-width: 700px) {
          font-size: 34px;
          font-style: normal;
          line-height: 36px;
          font-weight: 700;
        }
      }
      p {
        color: var(--text-sec);
        margin: 0 0 10px 0;
        line-height: 24px;
        opacity: 0.7;

        @media screen and (max-width: 700px) {
          text-align: left;
        }
      }

      .btn-toolbox {
        margin-top: 20px;
      }

      @media screen and (max-width: 1200px) {
        max-width: 600px;
        text-align: left;
      }
    }
  }
}
