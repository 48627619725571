.process {
  margin: 50px 0 50px 0;
  position: relative;

  .blur-image {
    img {
      position: absolute;
      top: 170px;
      left: -100px;
      width: 250px;

      @media (max-width: 900px) {
        display: none;
      }
      @media (max-width: 605px) {
        display: none;
      }
    }
  }
  .blur-image-2 {
    img {
      position: absolute;
      bottom: 10px;
      right: 70px;
      width: 120px;

      @media (max-width: 900px) {
        display: none;
      }
      @media (max-width: 605px) {
        display: none;
      }
    }
  }

  .wrapper {
    width: 65%;
    margin: 0 auto;
    display: flex;

    justify-content: center;
    flex-direction: column;

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;
      width: 100%;
      margin: 0 auto;

      span {
        font-weight: 700;
      }

      h2 {
        color: var(--head-txt);
        font-weight: 700;
        font-size: 72px;
        line-height: 75px;
        margin: 0px 0px 15px 0;
        max-width: 800px;

        @media (max-width: 1387px) {
          font-size: 65px;
          font-style: normal;
          line-height: 67px;
          font-weight: 700;
        }

        @media screen and (max-width: 1200px) {
          text-align: center;
        }

        @media screen and (max-width: 900px) {
          font-size: 60px;
          font-style: normal;
          line-height: 62px;
          font-weight: 700;
        }

        @media screen and (max-width: 700px) {
          font-size: 32px;
          font-style: normal;
          line-height: 38px;
          font-weight: 700;
        }
      }
      p {
        color: var(--text-sec);
        margin: 0 0 10px 0;
        max-width: 550px;
        line-height: 24px;
        opacity: 0.7;

        @media screen and (max-width: 700px) {
          text-align: left;
        }
      }

      .btn-toolbox {
        margin-top: 20px;
      }

      @media screen and (max-width: 1200px) {
        max-width: 600px;
        text-align: left;
      }
    }

    .process-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      flex-wrap: wrap;
      max-width: 800px;

      @media screen and (max-width: 700px) {
        width: 100% !important;
      }

      .process-card {
        background: #ffffff;
        box-shadow: 0px 15px 26px rgba(1, 73, 159, 0.05);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 40px;
        margin: 20px;

        &:hover {
          box-shadow: 0px 15px 26px rgba(1, 72, 159, 0.211);
          transition: all 0.3s linear;
          transform: scale(1.01);
          cursor: pointer;
        }

        img {
          width: 100%;

          @media screen and (max-width: 700px) {
            width: 50px;
            margin-right: 20px;
          }
        }

        @media screen and (max-width: 700px) {
          flex-direction: row;
          width: 100%;
          justify-content: flex-start;
          align-items: center;
          padding: 20px 20px;
        }
      }
    }
    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }
  }
}
