@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800&display=swap");

.project-content {
  width: 100%;
  padding: 50px 0 260px 0;
  // position: relative;

  .wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-bottom: 1000px;
    // display: flex;

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }
    img {
      height: 86px;
      margin: 0 auto;

      @media screen and (max-width: 900px) {
        height: 70px;
      }
    }
    h2 {
      font-size: 72px;
      color: var(--head-txt);
      text-align: center;
      line-height: 105%;
      font-weight: 900;

      @media screen and (max-width: 900px) {
        font-size: 35px;
      }
    }
    .wrapper-parap {
      width: 900px;

      @media (max-width:900px){
        width: 100%;
        text-align:justify !important;
      }
    }
    p {
      font-size: 18px;
      color: var(--text-sec);
      line-height: 170%;
      font-weight: 400;
      text-align: center;
      margin: 20px auto;
      opacity: 0.699999988079071;
      margin-top: 50px;

      @media screen and (max-width: 900px) {
        font-size: 16px;
        width: 300px;
      }
      @media screen and (min-height: 1024px) {
        font-size: 16px;
        width: 500px;
      }
    }
  }
  .project-list {
    margin-top: 10%;
    display: grid;
    grid-template-columns: 50% auto;
    row-gap: 53px;
    column-gap: 30px;
    position: absolute;
    margin-left: -32.5%;
    left: 55%;

    @media (max-width: 1387px) {
      margin-left: -45%;
    }

    @media (max-width: 1315px) {
      margin-left: -47.5%;
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .project-card {
      background-color: var(--white);
      box-shadow: 0px 15px 26px rgba(1, 73, 159, 0.05);
      border-radius: 7px;
      padding: 20px 30px;
      position: relative;
      margin-top: 10%;

      &:hover {
        box-shadow: 0px 15px 26px rgba(1, 72, 159, 0.311);
        transition: all 0.3s linear;
        transform: scale(1.05);
        cursor: pointer;

        @media screen and (max-width: 600px) {
          transform: scale(1.0001);
        }
      }
      .project-content-container {
        justify-content: center;
        margin: 0 auto;
        width: 400px;

        @media screen and(max-width:900px) {
          width: 240px;
        }

        h3 {
          font-size: 30px;
          font-weight: bold;
          color: var(--head-txt);
          margin-top: 7%;
        }
        p {
          text-align: left;
          font-size: 14px;
          line-height: 25px;
        }
      }
      .gray-matter {
        color: #0331601a;
        font-weight: bold;
        font-size: 60px !important;
        text-align: right !important;
        font-family: "Poppins", sans-serif;
      }

      .gloss-icon {
        position: absolute;
        top: -50px;
        right: 0;
        height: 70px;
      }
    }
  }
}
