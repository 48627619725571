.accordion {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  padding: 15px 20px;
  gap: 64px;
  border-radius: 20px;
  border: 0.5px solid #cad6f1;
  box-shadow: 0px 25px 50px 0px rgba(22, 25, 79, 0.05);
  overflow: hidden;
  display: block;

  .accordion-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }
  h4 {
    font-size: 18px;
    color: var(--head-txt);
  }
  .accordion-content {
    background-color: #ffffff;
    padding: 20px;
    overflow: hidden;
    transition: height 0.4s ease;
  }
}
