.empty-div {
  margin: 1300px 0 50px 0;
  position: relative;

  .blur-image {
    img {
      position: absolute;
      top: -550px;
      left: -100px;
      width: 250px;

      @media (max-width: 900px) {
        display: none;
      }
      @media (max-width: 605px) {
        display: none;
      }
    }
  }
  .blur-image-2 {
    img {
      position: absolute;
      top: -220px;
      right: 70px;
      width: 120px;

      @media (max-width: 900px) {
        display: none;
      }
      @media (max-width: 605px) {
        display: none;
      }
    }
  }

  @media (max-width: 900px) {
    margin: 1300px 0 50px 0;
  }
  @media (max-width: 605px) {
    margin: 1700px 0 50px 0;
  }
}
