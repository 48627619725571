// general config

@font-face {
  font-family: Poppins-Regular;
  src: url("../assets/fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("../assets/fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-Semi-Bold;
  src: url("../assets/fonts/poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("../assets/fonts/poppins/Poppins-Bold.ttf");
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: var(--full);
  scroll-behavior: smooth;
  font-family: Poppins-Regular, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  width: var(--full);
  height: var(--full);
  background: var(--main-bg);
  color: var(--text-primary);
}

html._fixed,
body._fixed {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}

#root {
  max-width: var(--wrapper-max-width);
  min-width: var(--wrapper-min-width);
  margin: 0 auto;
}
#tsparticles {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  position: absolute;
}

::-webkit-scrollbar {
  width: 8px;

  @media screen and (max-width: 900px) {
    height: 6px;
  }

  @media screen and (max-width: 600px) {
    height: 4px;
  }
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--pry-color);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(6, 31, 53, 0.7);
}

// 1114px 1023px 900px 700px

h1,
h2,
h3,
h4,
h5,
h6,
p {
  line-height: 1.2;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins-Bold, sans-serif;
}

p {
  font-size: 16px;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  outline: none;
  font-family: inherit;
  white-space: nowrap;
  text-decoration: none;
}

button {
  outline: none !important;
  font: inherit;
  color: inherit;
  cursor: pointer;
  border: 0;
}

.btn-primary {
  background-color: var(--btn-color);
  border: 1px solid var(--btn-color);
  border-radius: 4px;
  color: var(--white);
  font-family: Poppins-Regular, sans-serif;
  font-size: 16px;
  font-weight: 700;
  transition: background 0.6s ease-in-out;

  &:hover {
    border: 1px solid var(--btn-color);
    background: #0d4e993a;
    color: var(--btn-color);
    transition: background 0.6s ease-in-out;
  }
}

.btn-block {
  width: 100%;
  height: 58px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-large {
  width: 330px;
  height: 70px;
  max-width: 100%;
  display: flex;
  border-radius: 100px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    width: 290px;
  }
}

.btn-medium {
  width: 290px;
  height: 70px;
  max-width: 100%;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  object-fit: cover;
  display: block;
}

.label-wrapper {
  display: flex;
  justify-content: space-between;

  label {
    display: block;
    font-size: 15px;
    margin-bottom: 12px;
    color: var(--head-txt);
    font-family: Poppins-Medium, sans-serif;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #74808c;
  }
}

input:not([type="radio"]),
input:not([type="checkbox"]),
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1rem;
  outline: none;
  color: inherit;
  width: 100%;
  font-family: inherit;
  border: 1px solid #dadada;
  background: var(--white);
  border-radius: 5px !important;
  height: 55px !important;
  transition: all 0.3s ease-in-out;
  padding: 12px;
  color: #65676f;

  &:focus {
    border: 1px solid var(--pry-color);
  }
}

select {
  cursor: pointer;
}

.input-wrapper {
  width: 100%;
  position: relative;
  height: 56px;

  .input-icon {
    position: absolute;
    left: 16px;
    top: 25%;
  }

  > label {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    margin-bottom: 0px;
  }

  input {
    padding: 12px 38px 12px 48px !important;
  }
}

.textarea-wrapper {
  width: 100%;
  position: relative;
  // height: 56px;

  .input-icon {
    position: absolute;
    left: 16px;
    top: 10%;
  }

  textarea {
    padding: 12px 38px 12px 48px !important;
    resize: none;
    height: 150px !important;
  }
}

.checkbox-wrapper {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  width: fit-content;

  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    flex-shrink: 0;
    font: inherit;
    color: currentColor;
    width: 24px !important;
    height: 24px !important;
    border: 1px solid #093867;
    border-radius: 4px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: 16px;
      height: 16px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;

      // using the clip-path check-icon
      // box-shadow: inset 16px 16px #093867;
      // transform-origin: bottom left;
      // clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      // // Windows High Contrast Mode
      // background-color: CanvasText;

      // using the check-icon
      margin-top: 4px;
      background: url("../assets/images/check-icon.svg") no-repeat;
    }

    &:checked {
      &::before {
        transform: scale(1);
      }
    }

    &:focus {
      // outline: max(2px, 2px) solid currentColor;
      // outline-offset: max(2px, 2px);
    }

    &:disabled {
      --form-control-color: var(--form-control-disabled);
      color: var(--form-control-disabled);
      cursor: not-allowed;
    }
  }

  span {
    margin-left: 16px;
    font-size: 14px;
    color: #093867;
    font-family: Poppins-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
  }
}

.form-control--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.select-wrapper {
  width: 100%;
  position: relative;
  height: 50px;
  max-width: 100%;

  select {
    border: 1.5px solid #adb7be;
    color: #adb7be !important;
  }

  label {
    position: absolute !important;
    top: -12px !important;
    left: 10px !important;
    margin-bottom: 0px !important;
    font-size: 14px;
    line-height: 16px;
    color: #343434;

    background-color: var(--white);
    padding: 5px;
  }

  &::after {
    content: "";
    width: 10px;
    height: 6px;
    // background: url("/assets/images/chevron-down.svg") no-repeat center;
    background-size: cover;
    position: absolute;
    right: 20px;
    top: 70%;
    transform: translateY(-50%);
    pointer-events: none;
    display: block;
  }
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
  appearance: checkbox;
  border: 0;
  margin: 0;
  width: auto;
  height: auto;
  padding: 0;
}

input[type="radio"] {
  -webkit-appearance: radio;
  appearance: radio;
  border: 0;
  margin: 0;
  border-radius: 50% !important;
  width: auto;
  height: auto;
  padding: 0;
}

.backdrop {
  position: fixed;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.48);
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all 0.4s ease-in-out;
  // blur property
  // backdrop-filter: blur(10px);
  // -webkit-backdrop-filter: blur(10px);

  @media (min-width: 1265px) {
    display: none;
  }

  &.show {
    visibility: visible;
    pointer-events: all;
    opacity: 1;
  }
}

.hide-scrollbar {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

// dropzone
.dropzone {
  // border: solid 3px rgb(121, 121, 121) !important;
  border-style: dashed;
  border-color: #093867;
  border-width: 1px;
  border-radius: 10px;
  // height: 110px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  img {
    margin-bottom: 8px !important;
  }

  p {
    margin-bottom: 5px !important;
    color: #74808c;
    font-size: 16px;
    line-height: 17px;
    font-weight: 500;
  }

  .uploadedFile {
    ul {
      li {
        font-size: 14px;
        line-height: 16px;
        font-family: Poppins-Bold, sans-serif;
        font-weight: 700;
        color: var(--head-txt);
      }
      .error-size {
        color: red !important;
      }
    }
  }
}

// For error message form validation
.error-msg {
  color: red;
  font-size: 10px;
  margin-top: 2px;
}

// hamburger

.hamburger {
  padding: 0;
  width: fit-content;
}

.hamburger-box {
  border: none !important;
  outline: none !important;
  padding: 0 !important;
  width: 28px !important;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: var(--head-txt);
  width: 28px !important;
  height: 2.8px !important;
  margin: 0;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: var(--head-txt);
  width: 28px !important;
  height: 2.8px !important;
  margin: 0;
}

.hamburger:hover {
  opacity: 1 !important;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 33;
}

// .wrapper {
//   width: 85%;
//   margin: 0 auto;

//   @media (max-width: 1387px) {
//     width: 90%;
//   }

//   @media (max-width: 1315px) {
//     width: 95%;
//   }
// }

// .wrapper {
//   width: 85%;
//   margin: 0 auto;
//   display: flex;
//   align-items: center;
//   height: 100%;
//   max-width: var(--wrapper-max-width);

//   @media (max-width: 1387px) {
//     width: 90%;
//   }

//   @media (max-width: 1315px) {
//     width: 95%;
//   }

//   @media (max-width: 1264px) {
//     justify-content: space-between;
//   }
// }

// swiper

.custom-pag1 {
  display: flex;
  align-items: center;
  // margin-top: 100px;
  margin-bottom: 0px;
  justify-content: left;

  @media screen and (max-width: 1200px) {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 900px) {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  > span {
    width: 7px;
    height: 7px;
    opacity: 1;
    margin: 0 7px !important;

    &.swiper-pagination-bullet-active {
      border: 1px solid #01499f !important;
      width: 26px;
      height: 26px;
      background: transparent;
      // background: red;
      display: flex;
      align-items: center;
      justify-content: center;
      // opacity: 1;
      z-index: 2;

      &::before {
        content: "";
        width: 7px;
        height: 7px;
        // background: var(--white);
        background: #01499f !important;
        border-radius: 50%;
        z-index: 2;
      }
    }
  }
}

.custom-prev1 {
  position: absolute;
  width: 80px;
  height: 80px;
  // background: chartreuse;
  // background: url("../assets/images/prev.svg");
  backdrop-filter: blur(4px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -30px;
  // top: 50%;
  transform: translateY(-50%);
  color: var(--black);
  z-index: 32;
  font-size: 28px;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    width: 50px;
    height: 50px;
    font-size: 25px;
    left: 0;
  }
}

.custom-next1 {
  position: absolute;
  width: 80px;
  height: 80px;
  // background: chartreuse;
  // background: url("../assets/images/next.svg");
  backdrop-filter: blur(4px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -30px;
  // top: 50%;
  transform: translateY(-50%);
  color: var(--black);
  z-index: 32;
  font-size: 28px;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    width: 50px;
    height: 50px;
    font-size: 25px;
    // display: none;
    right: 0;
  }
}

.custom-next1,
.custom-prev1,
.custom-next2,
.custom-prev2 {
  &.swiper-button-disabled {
    pointer-events: all !important;
    cursor: not-allowed !important;
    // visibility: hidden;
  }
}
.animated {
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  // will-change: opacity, transform, visibility;
}
.animated.fadeIn {
  transform: none;
  visibility: visible;
}

.swiper-slide.swiper-slide-visible.swiper-slide-active {
  h1 {
    animation-name: fadeInDown;
    animation-duration: 2s;
  }
  p {
    animation-name: fadeInRight;
    animation-duration: 2s;
  }
  a {
    animation-name: fadeInUp;
    animation-duration: 2s;
  }
}

.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center;

  .swal2-title {
    color: var(--head-txt);
  }
  .swal2-html-container {
    color: var(--text-sec);
  }
  .swal2-styled.swal2-confirm {
    width: 230px;
    height: 70px;
    max-width: 100%;
    display: flex;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    background-color: var(--btn-color) !important;
    border: 1px solid var(--btn-color) !important;
    color: var(--white);
    font-family: Poppins-Regular, sans-serif;
    font-size: 16px;
    font-weight: 700;
    transition: background 0.6s ease-in-out;
    outline: none !important;
    box-shadow: none !important;

    &:hover {
      border: 1px solid var(--btn-color) !important;
      background-color: #0d4e993a !important;
      color: var(--btn-color) !important;
      transition: background 0.6s ease-in-out;
      outline: none !important;
    }

    @media screen and (max-width: 600px) {
      width: 190px;
    }
  }
}
