.design {
  padding: 100px 0;
  position: relative;

  @media (max-width: 605px) {
    padding: 50px 0 100px 0;
  }

  .blur-image {
    img {
      position: absolute;
      top: 250px;
      right: 70px;

      @media (max-width: 900px) {
        display: none;
      }
      @media (max-width: 605px) {
        display: none;
      }
    }
  }
  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }

    .left {
      width: 50%;
      max-width: 770px;

      @media screen and (max-width: 1200px) {
        width: 100%;
        max-width: 512px;
      }

      img {
        width: 70%;
        margin-left: 200px;

        @media screen and (max-width: 600px) {
          margin-left: 0px;
          margin: 0 auto;
        }

        @media screen and (max-width: 1200px) {
          margin-left: 0px;
          margin: 0 auto;
        }
      }

      @media screen and (max-width: 1200px) {
        order: 2;
      }
    }

    .right {
      flex: 1;

      @media screen and (max-width: 1200px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 48px;
        margin-left: 0px;
      }

      .content-design {
        display: flex;

        flex-direction: column;

        max-width: 600px;

        @media screen and (max-width: 1200px) {
          text-align: center;
          align-items: center;
        }

        @media screen and (max-width: 900px) {
          text-align: center;
          align-items: center;
        }

        @media screen and (max-width: 700px) {
          text-align: center;
          align-items: center;
          max-width: 340px;
        }

        span {
          font-weight: 700;
        }

        img {
          width: 11.5%;

          @media screen and (max-width: 700px) {
            width: 21.5%;
          }
        }

        h2 {
          color: var(--head-txt);
          font-weight: 700;
          font-size: 72px;
          line-height: 105%;
          margin: 15px 0;

          @media (max-width: 1387px) {
            font-size: 65px;
            font-style: normal;
            line-height: 67px;
            font-weight: 700;
          }

          @media screen and (max-width: 900px) {
            font-size: 60px;
            font-style: normal;
            line-height: 62px;
            font-weight: 700;
          }

          @media screen and (max-width: 700px) {
            font-size: 34px;
            font-style: normal;
            line-height: 36px;
            font-weight: 700;
          }
        }
        p {
          color: var(--text-sec);
          margin: 0 0 10px 0;
          line-height: 24px;
          opacity: 0.7;

          @media screen and (max-width: 1200px) {
            text-align: left;
          }
        }

        .btn-toolbox {
          margin-top: 20px;
        }
      }

      @media screen and (max-width: 1200px) {
        order: 1;
      }
    }
  }
}
