.hero-process {
  width: 100%;
  padding: 50px 0 50px 0;
  position: relative;

  @media screen and (max-width: 1200px) {
    height: auto;
    padding: 150px 0px 0px;
  }

  @media screen and (max-width: 900px) {
    height: auto;
    padding: 150px 0px 0px;
  }

  @media screen and (max-width: 600px) {
    height: auto;
    padding: 110px 0px 0px;
  }

  .round-blur {
    position: absolute;
    right: 20px;
    bottom: 50px;
    z-index: 2;
    width: 280px;
    height: 280px;

    @media (max-width: 900px) {
      right: 70px;
      bottom: -50px;
      z-index: 2;
      width: 200px;
      height: 200px;
    }

    @media (max-width: 600px) {
      right: 20px;
      bottom: -30px;
      z-index: 2;
      width: 200px;
      height: 200px;
    }
  }

  .round-blur-med {
    position: absolute;
    left: 20px;
    top: 170px;
    z-index: 2;
    width: 200px;
    height: 200px;

    @media (max-width: 1387px) {
      display: none;
    }

    @media (max-width: 1200px) {
      width: 100px;
      height: 100px;
      left: 200px;
      top: 20px;
      display: block;
    }

    @media (max-width: 900px) {
      width: 100px;
      height: 100px;
      left: 200px;
      top: 20px;
      display: block;
    }

    @media (max-width: 600px) {
      width: 100px;
      height: 100px;
      left: 200px;
      top: 20px;
      display: block;
    }
  }

  .round-blue-large {
    position: absolute;
    right: -30px;
    top: -95px;
    z-index: 2;
    display: block;

    @media (max-width: 1387px) {
      display: none;
    }

    @media (max-width: 1200px) {
      width: 100px;
      height: 100px;
      right: 190px;
      top: -30px;
      display: block;
    }
    @media (max-width: 900px) {
      width: 100px;
      height: 100px;
      right: 190px;
      top: -30px;
      display: block;
    }
    @media (max-width: 600px) {
      width: 100px;
      height: 100px;
      right: 190px;
      top: -30px;
      display: block;
    }
  }

  .round-blue-small {
    position: absolute;
    left: 20px;
    top: 10px;
    z-index: 2;

    @media (max-width: 1200px) {
      display: none;
    }

    @media (max-width: 900px) {
      display: none;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  .hero-skew {
    background: linear-gradient(
      180deg,
      rgba(249, 249, 249, 0.29) -14.99%,
      rgba(85, 202, 255, 0.52) 118.32%
    );
    width: 100% !important;
    height: 93% !important;
    transform: skewX(-38deg);
    position: absolute;
    top: 0;
    left: -20%;

    @media (max-width: 1200px) {
      transform: skewX(-0deg);
      transform: skewY(-20deg);
      left: 0%;
      top: -30%;
    }

    @media (max-width: 900px) {
      transform: skewX(-0deg);
      transform: skewY(-20deg);
      left: 0%;
      top: -20%;
    }

    @media (max-width: 600px) {
      transform: skewX(-0deg);
      transform: skewY(-20deg);
      left: 0%;
      top: -15%;
    }
  }

  .wrapper {
    width: 65%;
    margin: 0 auto;

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }

    .container {
      display: flex;
      position: relative !important;
      width: 97.5vw !important;
      left: 0;
      top: 0;
      right: 0;
      height: 100%;

      @media screen and (max-width: 1200px) {
        flex-direction: column;
        width: 100vw;
        transform: translateX(-50%);
        left: 50%;
        right: 50%;
      }

      .left {
        width: 40%;
        padding-top: 100px;
        color: var(--head-txt);
        position: relative;

        span {
          font-weight: 700;
        }

        h2 {
          animation-name: fadeInDown;
          animation-duration: 2s;
        }
        p {
          animation-name: fadeInRight;
          animation-duration: 2s;
        }
        a {
          animation-name: fadeInUp;
          animation-duration: 2s;
        }

        h2 {
          font-size: 72px;
          font-style: normal;
          line-height: 75px;
          max-width: 600px;
          letter-spacing: 1px;
          font-weight: 700;

          @media (max-width: 1387px) {
            font-size: 68px;
            font-style: normal;
            line-height: 80px;
            max-width: 600px;
            letter-spacing: 1px;
            font-weight: 700;
          }

          @media screen and (max-width: 900px) {
            font-size: 60px;
            font-style: normal;
            line-height: 65px;
            font-weight: 700;
          }

          @media screen and (max-width: 700px) {
            font-size: 40px;
            font-style: normal;
            line-height: 50px;
            font-weight: 700;
          }
        }

        p {
          max-width: 650px;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          color: var(--text-sec);
          letter-spacing: 1px;
          margin: 20px 0 20px 0;
          opacity: 0.7;

          @media screen and (max-width: 1200px) {
            max-width: 700px;
          }

          &:last-of-type {
            margin: 20px 0 40px 0;
          }
        }

        @media screen and (max-width: 1200px) {
          padding-bottom: 0px;
          padding-top: 0px;
          padding-left: 12px;
          display: flex;
          flex-direction: column;
          align-items: left;
          text-align: left;
          width: 100%;
        }

        @media screen and (max-width: 1200px) {
          padding-bottom: 0px;
          padding-top: 0px;
          padding-left: 10px;
          display: flex;
          flex-direction: column;

          align-items: left;
          text-align: left;
          width: 100%;
        }

        @media screen and (max-width: 600px) {
          padding-bottom: 0px;
          padding-top: 0px;
          padding-left: 6px;

          display: flex;
          flex-direction: column;
          align-items: left;
          text-align: left;
          width: 100%;
        }
        .subscribe {
          display: flex;

          @media screen and (max-width: 600px) {
            justify-content: center;
          }
        }
      }

      .right {
        flex: 1;
        height: 100%;

        @media screen and (max-width: 1200px) {
          width: 100%;
        }

        img {
          width: 76%;
          height: 100%;

          @media (max-width: 1387px) {
            width: 96%;
          }

          @media (max-width: 1315px) {
            width: 100%;
          }

          @media screen and (max-width: 900px) {
            max-height: 400px;
          }
        }
      }
    }
  }
}
