.footer {
  width: 100%;
  background: var(--sec-color);
  padding: 50px 0 50px;
  color: var(--white);

  @media (max-width: 903px) {
    padding: 50px 0 50px;
  }

  .wrapper {
    width: 65%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 100%;
    max-width: var(--wrapper-max-width);

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }

    @media (max-width: 1203px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .first {
        order: 3;
        padding-top: 30px;
      }

      .second {
        order: 1;
      }

      .last {
        order: 2;
        padding-bottom: 30px;
      }
    }

    .first {
      .logo {
        width: 132.07px;
        height: fit-content;

        @media (max-width: 1264px) {
          width: 100%;
        }
        img {
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  .second {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 200px;

    @media (max-width: 1200px) {
      margin-left: 0px;
      flex-direction: column;
      height: auto;
      border-bottom: solid 1px rgba(3, 49, 96, 0.09);
    }

    .nav-links {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1264px) {
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        width: var(--full);
        height: auto;
      }

      > li {
        margin-right: 26px;
        width: fit-content;
        height: fit-content;

        &:last-of-type {
          margin-right: 0;
        }

        @media (max-width: 1264px) {
          margin-bottom: 20px;
          width: var(--full);
          margin-right: 0px;
        }

        > a {
          font-size: 14px;
          color: var(--head-txt);
          font-family: Poppins-Medium, sans-serif;
        }
      }
    }
  }
  .last {
    .footer-socials {
      .social-link {
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .outro {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    @media (max-width: 1200px) {
      font-style: normal;
      font-weight: 400;
      margin-top: 10px;
      line-height: 170%;
      color: #033160;
      opacity: 0.7;
    }

    .copyright {
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #033160;
        opacity: 0.7;
      }
    }
    .policy-terms {
      display: flex;
      align-items: center;

      .policy {
        a {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 170%;
          color: #033160;
          opacity: 0.7;
        }
      }

      .terms {
        margin-left: 20px;

        a {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 170%;
          color: #033160;
          opacity: 0.7;
        }
      }

      @media (max-width: 1264px) {
        flex-direction: column;

        .terms {
          margin-left: 0px;
        }
      }
    }

    @media (max-width: 1264px) {
      .copyright {
        order: 2;
      }
      .policy-terms {
        order: 1;
      }
    }
  }
}
