.take-pride {
  margin: 1200px 0 50px 0;
  position: relative;


  .blur-image {
    img {
      position: absolute;
      top: 50px;
      left: 70px;

      @media (max-width: 900px) {
        display: none;
      }
      @media (max-width: 605px) {
        display: none;
      }
    }
  }
  .blur-image-2 {
    img {
      position: absolute;
      top: 250px;
      right: 70px;
      width: 150px;

      @media (max-width: 1200px) {
        display: none;
      }
      @media (max-width: 900px) {
        display: none;
      }
      @media (max-width: 605px) {
        display: none;
      }
    }
  }

  @media (max-width: 900px) {
    margin: 1000px 0 50px 0;
  }
  @media (max-width: 605px) {
    margin: 2150px 0 50px 0;
  }

  .wrapper {
    width: 65%;
    margin: 0 auto;
    display: flex;

    justify-content: center;
    flex-direction: column;

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;
      width: 100%;
      margin: 0 auto;

      span {
        font-weight: 700;
      }

      h2 {
        color: var(--head-txt);
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;
        margin: 0px 0px 15px 0;
        max-width: 800px;

        @media (max-width: 1387px) {
          font-size: 40px;
          font-style: normal;
          line-height: 44px;
          font-weight: 700;
        }

        @media screen and (max-width: 1200px) {
          text-align: center;
        }

        @media screen and (max-width: 900px) {
          font-size: 40px;
          font-style: normal;
          line-height: 62px;
          font-weight: 700;
        }

        @media screen and (max-width: 700px) {
          font-size: 32px;
          font-style: normal;
          line-height: 38px;
          font-weight: 700;
        }
      }
      p {
        color: var(--text-sec);
        margin: 0 0 10px 0;
        max-width: 700px;
        line-height: 24px;
        opacity: 0.7;

        @media screen and (max-width: 700px) {
          text-align: left;
        }
      }

      .btn-toolbox {
        margin-top: 20px;
      }

      @media screen and (max-width: 1200px) {
        max-width: 600px;
        text-align: left;
      }
    }

    .companies-logos {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      @media screen and (max-width: 900px) {
        overflow-x: scroll;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0;
      }

      @media screen and (max-width: 700px) {
        overflow-x: scroll;
        margin: 0;
      }

      .logo-scroll {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10%;

        .logo {
          margin: 10px 20px;
          width: 100px;
          height: 10%;
        }
      }
    }
    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
}
