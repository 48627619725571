.about-section {
  padding: 20px 0px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  @media screen and(max-width:900px) {
    padding: 0;
    margin: 0;
    display: block;
  }
  .gallery-pen {
    @media screen and(max-width:900px) {
      width: 60px;
    }
  }
  .container {
    width: 100%;
    margin: 10% 0;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 900px) {
      display: block;
    }

    .story-left {
      width: 40%;
      flex-shrink: 0;

      @media screen and(max-width:900px) {
        width: 100%;
      }
      img {
        height: 450px;
        // margin-left: auto;

        @media screen and(max-width:900px) {
          width: 200px;
          height: auto;
          margin: 0 auto;
        }
      }
    }

    .story-right {
      width: 60%;

      @media screen and(max-width:900px) {
        margin: 30px;
      }

      h3 {
        color: #093867;
        font-family: Poppins;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 105%;

        @media screen and(max-width:900px) {
          font-size: 40px;
        }
      }
      p {
        opacity: 0.899999988079071;
        color: #033160;
        font-size: 18px;
        font-weight: 400;
        line-height: 170%;
        margin: 20px 0;

        @media screen and(max-width:900px) {
          width: 140%;
          font-size: 15px;
        }
      }
    }
  }
  .misvis-container {
    width: 100%;
    display: flex;
    margin: 10% auto;
    justify-content: center;

    @media screen and(max-width: 900px) {
      display: block;
      padding: 0 30px;
      width: 100%;
    }
    .misVis-left {
      width: 50%;
      flex-shrink: 0;
      // margin-left: auto;

      @media screen and(max-width:900px) {
        width: 100%;
      }

      h3 {
        font-size: 50px;
        color: #033160;

        @media screen and(max-width: 900px) {
          font-size: 40px;
        }
      }
      p {
        font-size: 18px;
        color: #033160;
        line-height: 170%;
        font-style: "Poppins", sans-serif;
        opacity: 0.899999988079071;

        @media screen and(max-width:900px) {
          width: 100%;
          font-size: 15px;
        }
      }
    }
    .misVis-right {
      width: 40%;

      img {
        height: 500px;
        margin-left: auto;

        @media screen and(max-width:900px) {
          height: 300px;
          width: auto;
          margin: 30px 0px;
        }
      }
    }
  }
  .core-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10% 0%;

    @media screen and(max-width:900px) {
      display: block;
    }

    .core-left {
      width: 40%;

      @media screen and(max-width:900px) {
        width: 100%;
      }

      img {
        height: 450px;
        // margin-left: auto;

        @media (max-width: 900px) {
          width: 200px;
          height: auto;
          margin: 0 auto;
        }
      }
    }

    .core-right {
      width: 60%;

      @media (max-width: 900px) {
        width: 100%;
      }
    }

    @media (max-width: 900px) {
      padding: 0px 30px;
      width: 100%;
    }

    h3 {
      font-size: 50px;
      color: #033160;
      line-height: 108%;
      margin-bottom: 20px;

      @media (max-width: 900px) {
        font-size: 40px;
      }
    }
    p {
      color: #033160;
      opacity: 0.8;
      width: 670px;
      line-height: 170%;
      font-size: 18px;

      @media (max-width: 900px) {
        width: 100%;
        font-size: 15px
      }
    }
    ul {
      width: 650px;
      padding: 0px 21px;

      @media (max-width: 900px) {
        width: 100%;
      }

      li {
        color: #033160;
        opacity: 0.8;
        list-style-type: disc;
        margin-top: 20px;
        line-height: 140%;
        font-size: 18px;

        @media (max-width:900px){
          font-size: 15px;
        }
      }
    }
  }
  .struc-wrapper {
    width: 1080px;
    margin: 100px auto;

    @media (max-width: 900px) {
      padding: 0px 30px;
      width: 100%;
    }

    h3 {
      color: #093867;
      font-family: Poppins;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 105%;
      margin: 20px 0px;

      @media (max-width: 900px) {
        font-size: 40px;
      }
    }
    p {
      color: #033160;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      text-align: justify;

      @media (max-width: 900px) {
        font-size: 15px;
        text-align:left ;
      }
    }
  }
  .hierachy {
    width: 100%;
    margin: 0 auto;

    @media screen and(max-width:900px) {
      margin: 0 auto;
      width: 90%;
    }

    img {
      width: 800px;
      height: 100%;
      margin: 0 auto 10% auto;

      @media screen and(max-width: 900px) {
        width: 100%;
        height: 100%;
      }
    }
  }
}
