.nav {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 150px;

  @media (max-width: 1264px) {
    position: fixed;
    top: 70px;
    // left: -100vw;
    right: -100vw;
    z-index: 250;
    // transition: left 0.4s ease-in-out;
    transition: right 0.4s ease-in-out;

    background: var(--white);
    display: initial;
    justify-content: stretch;
    align-items: stretch;
    margin-left: 0;
    overflow-y: auto;
    padding: 40px 16px 120px;
    width: 300px;

    &.show {
      // left: 0;
      right: 0;
    }

    &.hidden {
      overflow: hidden;
    }
  }

  @media screen and (max-width: 299px) {
    width: 280px;
  }

  .navLinks {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1264px) {
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
      width: var(--full);
      height: auto;
    }

    > li {
      margin-right: 26px;
      width: fit-content;
      height: fit-content;

      &:last-of-type {
        margin-right: 0;
      }

      @media (max-width: 1264px) {
        margin-bottom: 40px;
        width: var(--full);
        margin-right: 0px;
      }

      > a {
        font-size: 14px;
        color: var(--head-txt);
        font-family: Poppins-Medium, sans-serif;

        @media (max-width: 1264px) {
          color: var(--head-txt) !important;
          font-size: 16px;
        }
      }

      .active {
        color: var(--btn-color) !important;
        font-size: 16px;
        font-family: Poppins-Bold, sans-serif;
      }
    }
  }

  .nav-end {
    display: flex;
    align-items: center;
    height: 100%;

    @media (max-width: 1264px) {
      flex-direction: column;
      align-items: stretch;
      height: auto;
    }
    .socials {
      .social-link {
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    .contactLink {
      width: fit-content;
      flex-shrink: 0;

      > a {
        font-family: Poppins-Medium, sans-serif;
        width: 165px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }
    }
  }
}
