.terms-content {
  width: 100%;
  padding: 50px 0 260px 0;
  // position: relative;

  .wrapper {
    width: 65%;
    margin: 0 auto;
    // display: flex;

    @media (max-width: 1387px) {
      width: 90%;
    }

    @media (max-width: 1315px) {
      width: 95%;
    }
  }

  .terms-container {
    h3 {
      font-style: normal;
      font-weight: 900;
      font-size: 28px;
      line-height: 35px;
      color: var(--head-txt);
    }
    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: var(--head-txt);
    }
    h6 {
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: var(--head-txt);
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: justify !important;
      color: var(--text-sec);
      opacity: 0.7;
    }
    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
    }
    ul {
      padding: 0 0 0 15px;

      li {
        text-align: justify !important;
        color: var(--text-sec);
        opacity: 0.7;
        list-style-type: square;
      }
    }

    // @media screen and (max-width: 600px) {
    //   padding: 10px;
    // }
  }
}
