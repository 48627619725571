:root {
  --black: #000000;
  --pry-color: #033160;
  --sec-color: #91ddf8;
  --main-blue: #01499f;
  --head-txt: #093867;
  --btn-color: #0d4e99;
  --form-control-color: #91ddf8;
  --form-control-disabled: #959495;

  --text-pry: #01499f;
  --text-sec: #033160;
  --main-bg: #ffffff;
  --white: #fff;

  --alert: #f8d7da;
  --alert-color: #721c24;
  --alert-border: #f5c6cb;

  --success: #d7f8de;
  --success-color: #1c7225;
  --success-border: #c6f5ca;
  --full: 100%;
  --radius: 2px;
  --tran: #ffffff00;
  --box-shadow: 0px 15px 26px rgba(1, 73, 159, 0.05);
  // --wrapper-max-width: 1980px;
  --wrapper-max-width: 2620px;
  --wrapper-min-width: 240px;
}
